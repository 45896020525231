var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"home",class:{ active: _vm.floag == true }},[_c('div',{staticClass:"top-img",on:{"click":function($event){return _vm.fileToZip('https://manage.sansongkeji.com/伞送货主.apk')}}},[_c('img',{staticClass:"top-bg",attrs:{"src":require("./assets/images/top.png")}})]),_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"item-div"},[_vm._m(0),_c('div',{staticClass:"bot-div"},[_c('div',{staticClass:"bot-item cor-left"},[_vm._m(1),_c('div',{staticClass:"bot-text",on:{"click":_vm.openApp1}},[_vm._v("应用商店下载")])]),_c('div',{staticClass:"bot-item cor-right"},[_vm._m(2),_c('div',{staticClass:"bot-text-cor",on:{"click":function($event){return _vm.fileToZip('https://manage.sansongkeji.com/伞送货主.apk')}}},[_c('a',[_vm._v("浏览器下载")])])])])]),_c('div',{staticClass:"xian"}),_c('div',{staticClass:"item-div"},[_vm._m(3),_c('div',{staticClass:"bot-div"},[_c('div',{staticClass:"bot-item cor-left"},[_vm._m(4),_c('div',{staticClass:"bot-text",on:{"click":_vm.openApp}},[_c('a',[_vm._v("应用商店下载")])])]),_c('div',{staticClass:"bot-item cor-right"},[_vm._m(5),_c('div',{staticClass:"bot-text-cor",on:{"click":function($event){return _vm.fileToZip('https://manage.sansongkeji.com/伞送物流.apk')}}},[_c('a',[_vm._v("浏览器下载")])])])])])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-name"},[_c('div',[_c('img',{staticClass:"icon-img",attrs:{"src":require("./assets/images/huozhu.png")}})]),_c('div',{staticClass:"text-div"},[_vm._v("伞送货主")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"xz-icon",attrs:{"src":require("./assets/images/loadOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"xz-icon",attrs:{"src":require("./assets/images/loadTwo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-name"},[_c('div',[_c('img',{staticClass:"icon-img",attrs:{"src":require("./assets/images/wuliu.png")}})]),_c('div',{staticClass:"text-div"},[_vm._v("伞送物流")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"xz-icon",attrs:{"src":require("./assets/images/loadOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"xz-icon",attrs:{"src":require("./assets/images/loadTwo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticClass:"box1",attrs:{"src":require("./assets/111.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }