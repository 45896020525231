<template>
  <div>
    <div class="home" :class="{ active: floag == true }">
      <div class="top-img" @click="fileToZip('https://manage.sansongkeji.com/伞送货主.apk')">
        <img src="./assets/images/top.png" class="top-bg" />
      </div>

      <div class="main-div">
        <div class="item-div">
          <div class="top-name">
            <div>
              <img src="./assets/images/huozhu.png" class="icon-img" />
            </div>
            <div class="text-div">伞送货主</div>
          </div>
          <div class="bot-div">
            <div class="bot-item cor-left">
              <div>
                <img src="./assets/images/loadOne.png" class="xz-icon" />
              </div>
              <div class="bot-text" @click="openApp1">应用商店下载</div>
            </div>
            <div class="bot-item cor-right">
              <div>
                <img src="./assets/images/loadTwo.png" class="xz-icon" />
              </div>
              <div class="bot-text-cor" @click="
                fileToZip('https://manage.sansongkeji.com/伞送货主.apk')
                ">
                <a>浏览器下载</a>
              </div>
            </div>
          </div>
        </div>
        <div class="xian" />

        <div class="item-div">
          <div class="top-name">
            <div>
              <img src="./assets/images/wuliu.png" class="icon-img" />
            </div>
            <div class="text-div">伞送物流</div>
          </div>
          <div class="bot-div">
            <div class="bot-item cor-left">
              <div>
                <img src="./assets/images/loadOne.png" class="xz-icon" />
              </div>
              <div class="bot-text" @click="openApp"><a>应用商店下载</a> </div>
            </div>
            <div class="bot-item cor-right">
              <div>
                <img src="./assets/images/loadTwo.png" class="xz-icon" />
              </div>
              <div class="bot-text-cor" @click="
                fileToZip('https://manage.sansongkeji.com/伞送物流.apk')
                ">
                <a>浏览器下载</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <!-- <img class="imgbox" src="./assets/bluejiantou.png" alt="">
      <div class="two">点击右上角菜单</div>
      <div class="twoo">“在浏览器打开”并安装伞送APP</div> -->

      <img class="box1" src="./assets/111.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      floag: false,
      brands: {
        "IPHONE": "IPHONE|IPAD|IPOD|IOS",

        "OPPO": "OPPO",

        "VIVO": "VIVO",

        "HONOR": "HONOR",

        "HUAWEI": "HUAWEI",

        "XIAOMI": "XIAOMI|REDMI",

        "360": "1801-A01|1707-A01|1509-A00",

        "SAMSUNG": "SAMSUNG"

      },

      hasOwnProp: '',

      userAgent: '',

      os: '',

      brand: '',
    }
  },
  methods: {
    openApp() {
      let device_type = window.navigator.userAgent;//获取userAgent信息
      console.log(device_type);
      let ua_pub = window.navigator.userAgent;
      console.log(ua_pub);
      let u = window.navigator.userAgent;

      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

      let ua = window.navigator.userAgent.toLowerCase();

      let isWeixin = ua.indexOf('micromessenger') !== -1;

      let href = window.location.href;

      if (isWeixin) {
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansonglogistics";

        return true;

      }

      else if (ua_pub.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        window.location.href = "https://apps.apple.com/us/app/伞送物流/id6450411192";
        // console.log('1', 1);
        // window.location.href = "https://itunes.apple.com/cn/app/伞送物流/id6450411192";
        return


      } else {
        if (ua.match(/MicroMessenger/i) === 'micromessenger' && isAndroid) {

          //应用宝
          // alert('应用宝')
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansonglogistics';

          // $('body,html').animate({ scrollTop: 0 }, 300);

        }

        if (href.indexOf('dym') !== -1) {
          // 大姨妈渠道

          // alert('dym')

          return

        } else {
          if (device_type.indexOf('HUAWEI') > -1) {
            // alert('huawei')

            window.location.href = "appmarket://details?id=com.bhs.sansonglogistics"

          } else if (device_type.indexOf('OPPO') > -1 || device_type.indexOf('Oppo') > -1) {
            // alert('OPPO')

            window.location.href = "oppomarket://details?id=com.bhs.sansonglogistics"

          } else if (device_type.indexOf('vivo') > -1) {
            // alert('vivo')
            window.location.href = "vivoMarket://details?id=com.bhs.sansonglogistics"

          } else if (device_type.indexOf('MI') > -1 || device_type.indexOf('Mi') > -1) {
            // alert('xiaomi')

            window.location.href = "mimarket://details?id=com.bhs.sansonglogistics"

          } else if (device_type.indexOf('baidu') > -1) {
            // alert('baidu')
            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansonglogistics"

          } else if (device_type.indexOf('360') > -1) {
            // alert('360')
            window.location.href = "intent://details?id=com.bhs.sansonglogistics#Intent;package=com.bhs.sansonglogistics;scheme=market;end;"

          } else {  ///其他安卓手机

            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansonglogistics"

          }

        }

      }
    },
    openApp1() {
      let device_type = window.navigator.userAgent;//获取userAgent信息
      console.log(device_type);
      let ua_pub = window.navigator.userAgent;
      console.log(ua_pub);
      let u = window.navigator.userAgent;

      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端

      let ua = window.navigator.userAgent.toLowerCase();

      let isWeixin = ua.indexOf('micromessenger') !== -1;

      let href = window.location.href;

      if (isWeixin) {
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansong";

        return true;

      }

      else if (ua_pub.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        // window.location.href = "https://itunes.apple.com/cn/app/伞送货主/id6462054974";
        window.location.href = "https://apps.apple.com/us/app/伞送货主/id6462054974";

        return
        // window.location.href = 'https://itunes.apple.com/cn/app/com.baohusan.transport'

      } else {
        if (ua.match(/MicroMessenger/i) === 'micromessenger' && isAndroid) {
          //应用宝

          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansong';

          // $('body,html').animate({ scrollTop: 0 }, 300);

        }

        if (href.indexOf('dym') !== -1) {
          // 大姨妈渠道

          // alert('dym')

          return

        } else {
          if (device_type.indexOf('HUAWEI') > -1) {
            // alert('huawei')

            window.location.href = "market://details?id=com.bhs.sansong"

          } else if (device_type.indexOf('OPPO') > -1 || device_type.indexOf('Oppo') > -1) {
            window.location.href = "market://details?id=com.bhs.sansong"

          } else if (device_type.indexOf('vivo') > -1) {
            window.location.href = "vivoMarket://details?id=com.bhs.sansong"

          } else if (device_type.indexOf('MI') > -1 || device_type.indexOf('Mi') > -1) {
            // alert('xiaomi')

            window.location.href = "mimarket://details?id=com.bhs.sansong"

          } else if (device_type.indexOf('baidu') > -1) {
            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansong"

          } else if (device_type.indexOf('360') > -1) {
            window.location.href = "intent://details?id=com.bhs.sansong#Intent;package=com.bhs.sansong;scheme=market;end;"

          } else {  ///其他安卓手机

            window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.bhs.sansong"

          }

        }

      }
    },
    fileToZip(url) {
      let isweixin = this.isWeiXin()
      if (isweixin) {
        const home = document.querySelector('.home')
        home.addEventListener('click', () => {
          const box = document.querySelector('.box')
          const box1 = document.querySelector('.box1')
          const maindiv = document.querySelector('.main-div')
          box.style.display = 'block'
          box1.style.display = 'block'
          home.style.display = 'none'
          maindiv.style.display = 'none'
          this.floag = true
        })
      } else {
        const a = document.createElement('a')
        a.href = url
        a.click()
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.imgbox {
  position: absolute;
  width: 80px;
  height: 160px;
  right: 0;
  top: 0;
}

.active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.two {
  position: absolute;
  top: 120px;
  right: 60px;
  z-index: 999;
  color: white;
  font-size: 18px;
}

.twoo {
  position: absolute;
  top: 150px;
  right: 60px;
  z-index: 999;
  color: white;
  font-size: 18px;
}

.box1 {
  display: none;
  background-image: url('./assets/111.jpg');
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 1;
  background-repeat: repeat;
  background-size: 100% auto;
}

.box {
  display: none;
  background-image: url('./assets/111.jpg');
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 1;
  background-repeat: repeat;
  background-size: 100% auto;
}

.home {
  width: 100%;
  position: relative;
}

.top-img {
  width: 100%;
  position: relative;
  z-index: 9;
}

.top-bg {
  width: 100%;
}

.main-div {
  width: 100%;
  min-height: 150px;
  position: relative;
  background-image: url('./assets/images/bot.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-top: -140px;
  z-index: 99;
  padding-top: 75px;
  padding-bottom: 30px;
}

.item-div {
  padding: 0 8px;
  height: auto;
  overflow: hidden;
}

.top-name {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.icon-img {
  width: 78px;
  height: 78px;
}

.text-div {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.bot-div {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bot-item {
  width: 155px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.xz-icon {
  width: 32px;
  margin: 5px 10px 0 20px;
}

.cor-left {
  background: #485ef4;
  border: 1px solid #485ef4;
}

.cor-right {
  background: #ffffff;
  border: 1px solid #485ef4;
}

.bot-text {
  color: #ffffff;
  font-size: 14px;
}

.bot-text-cor {
  color: #485ef4;
  font-size: 14px;
}

.xian {
  margin: 15px 8px;
  height: 1px;
  background-color: #485ef4;
}

/* .wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  display: none;
} */
/* .wxtip-icon {
  width: 52px;
  height: 67px;
  background: url('./assets/1.png') no-repeat;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
} */
/* .wxtip-txt {
  margin-top: 107px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
} */
</style>
